// Required for Bootstrap Accordion
import '../../node_modules/bootstrap/js/dist/collapse';

//import '../../node_modules/@fortawesome/fontawesome-pro/js/all.min.js';
import '../../node_modules/@fortawesome/fontawesome-pro/js/fontawesome.min.js';
import '../../node_modules/@fortawesome/fontawesome-pro/js/light.min.js';
import '../../node_modules/@fortawesome/fontawesome-pro/js/solid.min.js';
import '../../node_modules/@fortawesome/fontawesome-pro/js/brands.min.js';
import '../../node_modules/slick-carousel/slick/slick.min';
import '../../node_modules/mmenu-js/src/mmenu.js';

// Comment this out if your theme doesn't have Woocommerce to save some Kbs
//import './woocommerce.js';

(function ($) {
    "use strict";

    window.FontAwesomeConfig = {
        searchPseudoElements: true
    };

    $(document).ready(function () {

        const baseURI = window.location.protocol + "//" + window.location.host;

        $('a[href="#"]').on('click', function (event) {
            event.preventDefault();
        });

        $('.rjc-nav-search').on('click', function () {
            $('.rjc-search-panel').addClass('open');
            $('.rjc-search-panel > form > input[type="search"]').focus();
        });
        $('.rjc-search-panel').on('click keyup', function (event) {
            if (event.target.type !== 'search') {
                $('.rjc-search-panel').removeClass('open');
            }
        });

        $('.homepage-head-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '<span class="slick-prev pull-left"><i class="fal fa-angle-left"></i></span>',
            nextArrow: '<span class="slick-next pull-right"><i class="fal fa-angle-right"></i></span>',
            fade: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        dots: true,
                        arrows: false
                    }
                }
            ]
        });

        $('.card-slider').slick({
            rows: 2,
            slidesPerRow: 3,
            slidesToShow: 1,
            //slidesToScroll: 3,
            arrows: false,
            prevArrow: '<span class="slick-prev pull-left"><i class="fal fa-angle-left"></i></span>',
            nextArrow: '<span class="slick-next pull-right"><i class="fal fa-angle-right"></i></span>',
            fade: false,
            dots: true,
            responsive: [
                {
                    breakpoint: 575,
                    settings: {
                        slidesPerRow: 1,
                        slidesToShow: 1,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 775,
                    settings: {
                        slidesPerRow: 2,
                        slidesToShow: 1,
                        dots: true,
                        arrows: false
                    }
                }
            ]
        });

        $('.testimonial-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '<span class="slick-prev pull-left"><i class="fal fa-angle-left"></i></span>',
            nextArrow: '<span class="slick-next pull-right"><i class="fal fa-angle-right"></i></span>',
            fade: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        dots: true,
                        arrows: false
                    }
                }
            ]
        });

        $('.inner-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '<span class="slick-prev pull-left"><i class="fal fa-angle-left"></i></span>',
            nextArrow: '<span class="slick-next pull-right"><i class="fal fa-angle-right"></i></span>',
            fade: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        dots: true,
                        arrows: false
                    }
                }
            ]
        });

        $('.event-slider').slick({
            slidesToShow: 3,
            slidesToScroll: 2,
            arrows: true,
            prevArrow: '<span class="slick-prev pull-left"><i class="fal fa-angle-left"></i></span>',
            nextArrow: '<span class="slick-next pull-right"><i class="fal fa-angle-right"></i></span>',
            fade: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: false,
                        arrows: false,
                        autoplay: true,
                        autoplaySpeed: 3000
                    }
                }
            ]
        });

        // Nice floating labels trick for GravityForms
        let parentFieldSelector = ".gfield, .form-row:not(.wc-pao-addon-wrap), .comment-form-comment, .wc-pao-addon-container, .ginput_full, .ginput_left, .ginput_right";
        let inputFilter = 'input:not([type="radio"]):not([type="hidden"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"])';
        let alwaysInputTypes = ['date', 'datetime-local'];
        $(`.gfield ${inputFilter}, .gfield textarea, .form-row ${inputFilter}, .form-row textarea`).filter(function(){
            return $("label", $(this).closest(parentFieldSelector)).length > 0;
        }).on("change input keyup", function(){
            if($(this).val() == "" && alwaysInputTypes.indexOf($(this).attr("type")) === -1) {
                $(this).closest(parentFieldSelector).removeClass("withinput");
            } else{
                $(this).closest(parentFieldSelector).addClass("withinput");
            }
        }).on('focus', function(){
            $(this).closest(parentFieldSelector).addClass("withinput");
        }).on('blur', function(){
            if($(this).val() == "" && alwaysInputTypes.indexOf($(this).attr("type")) === -1) {
                $(this).closest(parentFieldSelector).removeClass("withinput");
            }
        }).each(function(){
            $(this).closest(parentFieldSelector).addClass("floating");
            if($(this).val() != "" || alwaysInputTypes.indexOf($(this).attr("type")) != -1) {
                $(this).closest(parentFieldSelector).addClass("withinput");
            }

            $("label", $(this).closest(parentFieldSelector)).click((e) => {
                e.preventDefault();
                $(this).focus();
            });
        });

        // Contact Page on Desktop Textarea height
        $(".gfield.side-message").each(function(){
            let h = 0;
            let lastMargin = 0;
            $(this).siblings(".gfield:visible").each(function(){
                h += $(this).outerHeight(true);
                lastMargin = $(this).outerHeight(true) - $(this).height();
            });
            $("textarea", this).css("height", (h - lastMargin) + "px").removeAttr("rows");
        });

        let facebookURI = $('.rjc-facebook-uri').attr('href');
        let twitterURI = $('.rjc-twitter-uri').attr('href');
        let linkedinURI = $('.rjc-linkedin-uri').attr('href');
        let youtubeURI = $('.rjc-youtube-uri').attr('href');
        let instagramURI = $('.rjc-instagram-uri').attr('href');

        new Mmenu("#rjc-mmenu", {
            "extensions": [
                "theme-dark",
                "pagedim-black",
                "position-right"
            ],
            "navbar": {
                title: "",
                titleLink: "none"
            },
            "navbars": [
                {
                    "position": "top",
                    "content": [
                        "<span class='mmenu-social-icon'><a href='" + facebookURI + "' title='Facebook' target='_blank'><i class='fab fa-facebook-f'> </i></a></span>",
                        "<span class='mmenu-social-icon'><a href='" + twitterURI + "' title='Twitter' target='_blank'><i class='fab fa-twitter'> </i></a></span>",
                        "<span class='mmenu-social-icon'><a href='" + linkedinURI + "' title='LinkedIn' target='_blank'><i class='fab fa-linkedin-in'> </i></a></span>",
                        "<span class='mmenu-social-icon'><a href='" + youtubeURI + "' title='Youtube' target='_blank'><i class='fab fa-youtube'> </i></a></span>",
                        "<span class='mmenu-social-icon'><a href='" + instagramURI + "' title='Instagram' target='_blank'><i class='fab fa-instagram'> </i></a></span>"
                    ]
                },
                {
                    "position": "bottom",
                    "content": [
                        "<form action='" + baseURI + "' method='get'><input type='input' name='s' placeholder='Search...' class='text-input' /><button type='submit' class='mmenu-search-button'><i class='fa fa-search'></i></button><input type='hidden' name='all' value='1' /></form>"
                    ]
                }
            ],
            "classNames": [
                {
                    "selected": "active"
                }
            ]
        });

        // Show people
        var staffShowing = 0,
            staffMember;
        $(".wrapper").on("click", function (e) {
            e.preventDefault();
            staffMember = $(this).data("bio");
            $(".person-bio").hide();
            $(".person-bio-" + staffMember).show();
            $("#people-modal").addClass("show");
        });
        $("#people-modal").on("click", function (e) {
            if (e.target !== this) return;
            $("#people-modal").removeClass("show");
        });
        $(".people-close").on("click", function (e) {
            e.preventDefault();
            $("#people-modal").removeClass("show");
        });

    });

}(jQuery));